export const CA = "3uPF7WqZDbhYQNmJYfmc663Qmsc9ohYjJ8p9B5qKrgNp";
export const TELEGRAM = "https://t.me/pepeplane";
export const TWITTER = "https://twitter.com/Pepeplanee";
export const BUY_LINK =
  "https://raydium.io/swap/?outputCurrency=3uPF7WqZDbhYQNmJYfmc663Qmsc9ohYjJ8p9B5qKrgNp";
export const DEXSCREENER =
  "https://dexscreener.com/solana/b4hnbpkthpodjfkxwg1cqhhdygei4uhcebt6j4uk5tor";
export const RAYDIUM =
  "https://raydium.io/swap/?outputCurrency=3uPF7WqZDbhYQNmJYfmc663Qmsc9ohYjJ8p9B5qKrgNp";
export const JUPITER =
  "https://jup.ag/swap/So11111111111111111111111111111111111111112-3uPF7WqZDbhYQNmJYfmc663Qmsc9ohYjJ8p9B5qKrgNp?inAmount=";
export const DEXTOOLS =
  "https://www.dextools.io/app/en/solana/pair-explorer/B4HNBPkTHpodjfkxWg1CQhHdYgei4uhcEBt6j4uK5Tor?t=1714707732011";

export const TELEGRAM_DEV = "https://t.me/lettodev";
export const TWITTER_DEV = "https://twitter.com/letto_dev";
